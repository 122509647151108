import 'scroll-behavior-polyfill';

export default {
  finalize() {
    /** Remove blurry placeholder from images - see https://www.industrialempathy.com/posts/image-optimizations/ **/
    document.body.addEventListener('load', (e) => {
        if (e.target.tagName != 'IMG') {
          return;
        }
        // Remove the blurry placeholder.
        e.target.style.backgroundImage = 'none';
      },
      /* capture */ true)

    /** Handle scrolling with debounce */
    let ticking = false;

    function update() {
      // reset the tick so we can
      // capture the next onScroll
      ticking = false;
      if (window.scrollY > 80) {
        document.body.classList.add('scrolled-down');
      } else {
        document.body.classList.remove('scrolled-down');
      }
    }

    function onScroll() {
      if (!ticking) {
        requestAnimationFrame(update);
      }
      ticking = true;
    }

    window.addEventListener('scroll', onScroll, false);
    /** end scrolling handling **/

    if (document.getElementsByClassName('swiper-container').length > 0) {
      // Inizializza tutti gli swiper tranne #delta-promo
      /* global Swiper */
      document.querySelectorAll('.swiper-container').forEach((swiperEl) => {
        if (swiperEl.id !== 'deltaPromo') {
          new Swiper(swiperEl, {
            loop: true,
            pagination: {
              el: '.swiper-pagination',
              type: 'fraction',
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            effect: 'fade',
            fadeEffect: {
              crossFade: true,
            },
            a11y: {
              prevSlideMessage: 'Previous slide',
              nextSlideMessage: 'Next slide',
            },
            autoplay: {
              delay: 5000,
            },
          });
        }
      });

      // Inizializza #deltaPromo separatamente
      if (document.getElementById('deltaPromo')) {
        new Swiper('#deltaPromo', {
          slidesPerView: 1.2,
          centeredSlides: true,
          spaceBetween: 20,
          loop: true,
          breakpoints: {
            1024: { slidesPerView: 1.3 },
            1512: { slidesPerView: 2.0 },
            1920: { slidesPerView: 2.3 },
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      }
    }
  },
};
